body,
div,
iframe,
ul,
ol,
dl,
dt,
dd,
li,
dl,
h1,
h2,
h3,
h4,
table,
th,
td,
input,
button,
select,
textarea {
	margin: 0;
	padding: 0;
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
		"Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
a {
	text-decoration: none;
	color: #000;
}

ol,
ul,
li {
	list-style: none;
}
i {
	font-style: normal;
}
html,
body,
#root {
	height: 100%;
  font-size: 14px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
